import React, { useState } from "react";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import styled from "styled-components";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import {
  innerWidth,
  mainCyan,
  lightPurple,
  mainPurple,
  screen,
} from "../../components/variables";
import {
  IconCircleLightPurpleLg,
  IconCircleDarkPurpleMd,
} from "../../components/circles";
import { formatDate } from "../../helpers";
import ButtonUnderlined from "../../components/buttons/button-underlined";
import CourseEnquiryForm from "../../components/course-enquiry-form";
import FaqRow from "../../components/faq-row";
import dayjs from "dayjs";

const Wrapper = styled.div`
  .inner-wrapper {
    max-width: ${innerWidth};
    margin: 0 auto;
    padding: 0 32px;
    @media ${screen.xxsmall} {
      padding: 0 42px;
    }
    @media ${screen.medium} {
      padding: 0;
    }

    &--header {
      display: flex;
      flex-direction: column;
      @media ${screen.xsmall} {
        flex-direction: row;
      }
    }
  }

  .header {
    margin: ${(props) => (props.isThereCourse ? "0" : "0 0 60px 0")};
    @media ${screen.xxsmall} {
      margin: 0 0 110px 0;
    }

    .col {
      &--img {
        width: 100%;
        order: 2;
        position: relative;
        max-width: 286px;
        margin: 26px auto 0 auto;
        @media ${screen.xxsmall} {
          max-width: 580px;
          margin: 32px auto 0 auto;
        }
        @media ${screen.xsmall} {
          order: 0;
          width: 50%;
          max-width: none;
          padding: 0 70px 0 0;
          margin: 0;
        }

        .icon-circle {
          display: block;
          @media ${screen.xsmall} {
            display: none;
          }
          @media ${screen.small} {
            display: block;
          }
          &--light-purple {
            width: 23px;
            bottom: 93px;
            right: 29px;
            @media ${screen.small} {
              width: 50px;
              bottom: 95px;
              right: 125px;
            }
          }
          &--dark-purple {
            width: 49px;
            bottom: 122px;
            right: 0;
            @media ${screen.small} {
              width: 82px;
              bottom: 145px;
              right: 55px;
            }
          }
        }
      }

      &--text {
        @media ${screen.xsmall} {
          width: 50%;
        }

        .page-label {
          font-size: 0.93rem;
          letter-spacing: 2px;
          font-weight: 900;
          margin: 0 0 11px 0;
          display: block;
          @media ${screen.xxsmall} {
            font-size: 1.1rem;
            margin: 0 0 8px 0;
          }
        }

        .heading {
          font-weight: 900;
          line-height: 1.22;
          max-width: 500px;
          position: relative;
          font-size: 2rem;
          @media ${screen.xxsmall} {
            font-size: 3.8rem;
          }
          @media ${screen.small} {
            font-size: 5rem;
            line-height: 1.09;
          }
        }

        .description {
          margin: 23px 0 38px auto;
          max-width: 590px;
          display: none;
          @media ${screen.xsmall} {
            display: block;
          }

          p {
            margin: 0 0 21px 0;
            font-size: 1.02rem;
            @media ${screen.small} {
              font-size: 1.06rem;
            }
            &:last-child {
              margin: 0;
            }
          }

          strong {
            font-weight: 700;
          }

          a {
            color: ${mainCyan};
            text-decoration: underline;
            @media ${screen.withCursor} {
              &:hover {
                color: ${lightPurple};
              }
            }
          }

          &--bold {
            font-weight: 700;
            font-size: 1.25rem;
            @media ${screen.xxsmall} {
              font-size: 1.52rem;
            }
            @media ${screen.small} {
              font-size: 1.55rem;
            }
          }
        }
      }

      &--mobile-only {
        display: block;
        order: 4;
        @media ${screen.xxsmall} {
          padding: 34px 0;
        }
        @media ${screen.xsmall} {
          display: none;
        }

        .description {
          display: block;
          max-width: none;
        }
      }
    }
  }

  .section {
    margin: 0 0 60px 0;
    @media ${screen.xxsmall} {
      margin: 0 0 110px 0;
    }

    .heading {
      font-weight: 900;
      line-height: 1.22;
      font-size: 1.56rem;
      @media ${screen.xxsmall} {
        font-size: 2.5rem;
        line-height: 1.19;
      }
      @media ${screen.small} {
        font-size: 3rem;
      }
    }

    .course-list {
      display: flex;
      flex-wrap: wrap;
      margin: 35px 0 0 0;
      @media ${screen.xxsmall} {
        margin: 70px -40px 0 -40px;
      }

      &__each {
        margin: 0 0 60px 0;
        display: flex;
        flex-direction: column;
        @media ${screen.xxsmall} {
          margin: 0 40px 90px 40px;
          width: calc(50% - 80px);
        }
        @media ${screen.small} {
          width: calc(33.33% - 80px);
        }

        .title {
          font-weight: 700;
          line-height: 1.4;
          margin: 0 0 13px 0;
          font-size: 1.25rem;
          @media ${screen.xxsmall} {
            font-size: 1.52rem;
          }
          @media ${screen.small} {
            font-size: 1.55rem;
          }
        }

        .date-time {
          font-weight: 700;
          font-size: 1.02rem;
          line-height: 1.58;
          margin: 0 0 14px 0;
          @media ${screen.xxsmall} {
            font-size: 1.03rem;
          }
          @media ${screen.small} {
            font-size: 1.07rem;
          }

          .pipe-symbol {
            color: ${lightPurple};
            margin: 0 10px;
          }
        }

        .pricing {
          color: ${mainPurple};
          font-weight: 700;
          font-size: 1.02rem;
          margin: 0 0 4px 0;
          @media ${screen.small} {
            font-size: 1.06rem;
          }
        }

        .description {
          margin: 0 0 26px 0;
          @media ${screen.xxsmall} {
            margin: 0 0 33px 0;
          }

          p {
            margin: 0 0 21px 0;
            font-size: 1.02rem;
            @media ${screen.small} {
              font-size: 1.06rem;
            }
            &:last-child {
              margin: 0;
            }
          }

          a {
            color: ${mainCyan};
            text-decoration: underline;
            @media ${screen.withCursor} {
              &:hover {
                color: ${lightPurple};
              }
            }
          }
        }
      }
    }
  }
`;

const ShortCoursesPage = ({ data }) => {
  const [isCourseEnquiry, setIsCourseEnquiry] = useState(false);
  const [courseTitle, setCourseTitle] = useState("");

  const {
    banner_heading,
    banner_description,
    banner_image_large,
    course_list,
    title_tag,
    meta_description,
  } = data.content.data;

  const isFutureDate = (date) => {
    // validate prevent error
    if (date) {
      // get today's date formatted in e.g. 2021-02-14
      const nowDate = dayjs().format("YYYY-MM-DD");

      // split input parameter string to e.g. "20210214"
      const inputDate = date.split("-").join("");

      // split todays date string to e.g. "20210214"
      const nowDateFormatted = nowDate.split("-").join("");

      // transform to Number for comparison
      const numberedInputDate = Number(inputDate);
      // transform to Number for comparison
      const numberedNowDateFormatted = Number(nowDateFormatted);

      // if comparison less than zero means the date is in the past
      if (numberedInputDate - numberedNowDateFormatted < 0) {
        return false;
      } else {
        return true;
      }
    }
  };

  const openCourseForm = (e, title) => {
    e.preventDefault();
    setIsCourseEnquiry(true);
    setCourseTitle(title);
  };

  const pageURL = typeof window !== "undefined" && window.location.href;

  // courses to render - only show if the date is in the future
  const filteredCourses = course_list.filter((item) => isFutureDate(item.date));

  const isThereCourse = filteredCourses.length > 0;

  return (
    <Layout noCirclesOnMobileFooter>
      <SEO
        title={title_tag || "Short Courses"}
        description={meta_description}
        image={banner_image_large.thumbnails.cropped.url}
        url={pageURL}
        webPageInfo={{
          name: title_tag || "Short Courses",
          url: pageURL,
          description: meta_description,
        }}
      />
      <Wrapper isThereCourse={isThereCourse}>
        <header className="header">
          <div className="inner-wrapper inner-wrapper--header">
            <div className="col col--img">
              <GatsbyImage
                image={banner_image_large.gatsbyImageData}
                alt={banner_image_large.alt || "Featured"}
              />
              <IconCircleLightPurpleLg className="icon-circle icon-circle--light-purple" />
              <IconCircleDarkPurpleMd className="icon-circle icon-circle--dark-purple" />
            </div>
            <div className="col col--text">
              <span className="page-label">SERVICES</span>
              <h1 className="heading">{banner_heading.text}</h1>

              <div
                className="description"
                dangerouslySetInnerHTML={{ __html: banner_description.html }}
              />
              {!isThereCourse && (
                <p className="description description--bold">
                  We have no upcoming courses at the moment. Please check back
                  another time!
                </p>
              )}
            </div>

            <div className="col col--text col--mobile-only">
              <div
                className="description"
                dangerouslySetInnerHTML={{ __html: banner_description.html }}
              />

              {!isThereCourse && (
                <p className="description description--bold">
                  We have no upcoming courses at the moment. Please check back
                  another time!
                </p>
              )}
            </div>
          </div>
        </header>

        {isThereCourse && (
          <section className="section" data-sal="fade" data-sal-duration="700">
            <div className="inner-wrapper">
              <h2 className="heading">Upcoming Courses</h2>
              <ul className="course-list">
                {filteredCourses.map((item, i) => (
                  <li className="course-list__each" key={item.title.text + i}>
                    <h2 className="title">{item.title.text}</h2>
                    <div className="date-time">
                      {formatDate(item.date)}
                      <span className="pipe-symbol">|</span> {item.time}
                    </div>

                    {item.pricing && (
                      <p className="pricing">Price: {item.pricing}</p>
                    )}

                    <div
                      className="description"
                      dangerouslySetInnerHTML={{
                        __html: item.description.html,
                      }}
                    />

                    <ButtonUnderlined
                      className="btn"
                      onClick={(e) => {
                        openCourseForm(e, item.title.text);
                      }}
                      label="REGISTER"
                    />
                  </li>
                ))}
              </ul>
            </div>
          </section>
        )}

        <FaqRow />

        <CourseEnquiryForm
          isCourseEnquiry={isCourseEnquiry}
          setIsCourseEnquiry={setIsCourseEnquiry}
          courseTitle={courseTitle}
          setCourseTitle={setCourseTitle}
        />
      </Wrapper>
    </Layout>
  );
};

export default ShortCoursesPage;

export const dataQuery = graphql`
  {
    content: prismicShortCoursesPage {
      data {
        banner_heading {
          text
        }
        banner_description {
          html
        }
        banner_image_large {
          alt
          thumbnails {
            cropped {
              url
            }
          }
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        }
        course_list {
          title {
            text
          }
          pricing
          date
          time
          description {
            html
          }
        }
        title_tag
        meta_description
      }
    }
  }
`;
