const getHtmlEmailTemplate = (title, input) => {
  return `
  <table width="600" style="padding: 15px 30px 30px 30px; font-family: Verdana, sans-serif; color: #262626;">
    <tr>
      <td>
        <h3 style="color: #6B5ED8; font-size: 20px; font-weight: 700;">
          Short Course Enquiry
        </h3>
      </td>
    </tr>
    <tr>
      <td>
        <h3 style="font-size: 18px; font-weight: 600; margin: 0; text-decoration: underline;">
          Details:
        </h3>
      </td>
    </tr>
    <tr>
      <td>
        <p style="font-size: 15px; font-weight: 400;">
          <strong>Course:</strong> ${title}
        </p>
        <p style="font-size: 15px; font-weight: 400;">
          <strong>First name:</strong> ${input.first_name}
        </p>
        <p style="font-size: 15px; font-weight: 400;">
          <strong>Last name:</strong> ${input.last_name}
        </p>
        <p style="font-size: 15px; font-weight: 400;">
          <strong>Email:</strong> ${input.email}
        </p>
        <p style="font-size: 15px; font-weight: 400;">
          <strong>Phone:</strong> ${input.phone_number || ""}
        </p>
        <br />
      </td>
    </tr>
  </table>`;
};

export default getHtmlEmailTemplate;
