import React, { useState } from "react";
import styled from "styled-components";
import { ScrollLocky } from "react-scroll-locky";
import { motion, AnimatePresence } from "framer-motion";
import getHtmlEmailTemplate from "./html_template";
import {
  dirtyWhite,
  mainCyan,
  mainBlack,
  emailRegex,
  mainOrange,
  lightPurple,
  screen,
} from "../../components/variables";

import { encode } from "../../helpers";
import axios from "axios";
import ButtonFilled from "../buttons/button-filled";
import IconClose from "../../images/svg/icon-close.svg";

import IconSpinner from "../../images/svg/icon-loading.svg";
import IconCheck from "../../images/svg/icon-check.svg";

// motion framer animation props value
const container = {
  hidden: {
    opacity: 0,
  },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.1,
    },
  },
};

const item = {
  show: {
    y: 0,
    opacity: 1,
  },
  hidden: {
    y: 10,
    opacity: 0,
  },
};

const Wrapper = styled(motion.div)`
  background: rgba(255, 255, 255, 0.9);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: scroll;

  .container {
    background: ${dirtyWhite};
    border-radius: 13px;
    color: ${mainBlack};
    max-width: 1020px;
    width: 100%;
    padding: 42px 32px 48px 32px;
    position: relative;
    margin: 0 16px;
    box-shadow: 0 5px 8px -2px rgba(0, 0, 0, 0.1);
    max-height: calc(100vh - 154px);
    overflow-y: ${(props) => (props.isLoading ? "hidden" : "scroll")};
    @media ${screen.xxsmall} {
      max-height: calc(100vh - 80px);
      padding: 50px;
      margin: 0 50px;
    }

    .title {
      letter-spacing: 2px;
      font-weight: 900;
      font-size: 0.8rem;
      margin: 0 0 10px 0;
      @media ${screen.xxsmall} {
        font-size: 1.08rem;
        margin: 0 0 20px 0;
      }
      @media ${screen.small} {
        font-size: 1.1rem;
      }
    }

    .heading {
      font-weight: 900;
      line-height: 1.22;
      position: relative;
      font-size: 1.56rem;
      position: relative;
      @media ${screen.xxsmall} {
        font-size: 3rem;
      }
      @media ${screen.small} {
        font-size: 3rem;
        line-height: 1.19;
      }
    }

    .subheading {
      font-weight: 700;
      line-height: 1.4;
      margin: 36px 0 21px 0;
      font-size: 1.25rem;
      @media ${screen.xxsmall} {
        font-size: 1.52rem;
      }
      @media ${screen.small} {
        font-size: 1.55rem;
      }
    }

    .row {
      display: flex;
      flex-direction: column;
      @media ${screen.xxsmall} {
        margin: 0 0 18px 0;
        flex-direction: row;
      }

      &__col {
        width: 100%;
        @media ${screen.xxsmall} {
          width: 50%;
        }

        &--right {
          @media ${screen.xxsmall} {
            margin: 0 0 0 70px;
          }
        }
      }

      input {
        background: none;
        color: ${mainBlack};
        width: 100%;
        border: 0;
        border-bottom: 2px ${mainCyan} solid;
        font-weight: 600;
        outline: none;
        border-radius: 0;
        line-height: 2;
        font-size: 16px;
        margin: 0 0 22px 0;
        padding: 0;
        @media ${screen.xxsmall} {
          font-size: 1.06rem;
          margin: 0;
        }
        @media ${screen.small} {
          font-size: 1.1rem;
        }

        &::placeholder {
          color: ${mainBlack};
          opacity: 0.6;
          font-weight: 400;
        }
      }

      &--submit-btn {
        display: flex;
        margin: 16px 0 0 0;
        flex-direction: column;
        @media ${screen.xxsmall} {
          flex-direction: row;
          align-items: flex-end;
          justify-content: space-between;
          margin: 50px 0 0 0;
        }

        p {
          letter-spacing: 1px;
          font-weight: 900;
          text-transform: uppercase;
          font-size: 0.8rem;
          margin: 0 0 26px 0;
          @media ${screen.xxsmall} {
            font-size: 0.8rem;
            margin: 0;
          }
          @media ${screen.small} {
            font-size: 0.85rem;
          }

          a {
            color: ${mainCyan};
            @media ${screen.withCursor} {
              &:hover {
                color: ${lightPurple};
              }
            }
          }
        }
      }

      .invalid {
        border-bottom: 2px ${mainOrange} solid;
        color: ${mainOrange};

        &::placeholder {
          color: ${mainOrange};
        }
      }
    }

    .icon-close {
      position: absolute;
      top: 32px;
      z-index: 5;
      background: none;
      cursor: pointer;
      right: 32px;

      span {
        display: block;
      }

      .close-inner {
        fill: ${mainBlack};
      }

      svg {
        width: 18px;
        height: 18px;
        @media ${screen.xxsmall} {
          width: 28px;
          height: 28px;
        }
      }
    }

    #course-enquiry-spinner {
      position: fixed;
      background: rgba(255, 255, 255, 0.975);
      border-radius: 13px;
      width: calc(100% - 32px);
      height: 100%;
      max-width: 1020px;
      max-height: 594px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 4;
      @media ${screen.xxsmall} {
        width: 100%;
        max-height: 490px;
      }

      .icon {
        svg {
          width: 160px;
          height: 160px;
          @media ${screen.xxsmall} {
            width: 220px;
            height: 220px;
          }
        }
      }

      .msg {
        font-size: 1.16rem;
        text-align: center;
        color: ${mainBlack};
        @media ${screen.xxsmall} {
          font-size: 2rem;
        }
        @media ${screen.xsmall} {
          font-size: 2.36rem;
        }

        .icon {
          display: block;
          margin: 0 0 2px 0;
          @media ${screen.xxsmall} {
            margin: 0 0 7px 0;
          }

          svg {
            width: 30px;
            height: 30px;
            @media ${screen.xxsmall} {
              width: 66px;
              height: 66px;
            }

            .e6d6beab-9455-4e6c-aa7a-7c7ada802c8f {
              fill: ${mainCyan};
            }
          }
        }
      }
    }

    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-thumb {
      background: ${mainCyan};
    }
  }
`;

const CourseEnquiryForm = ({
  isCourseEnquiry,
  setIsCourseEnquiry,
  courseTitle,
  setCourseTitle,
}) => {
  const [userInput, setUserInput] = useState({});
  const [validate, setValidate] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  // ********START USER INPUT

  const handleUserInput = (e) => {
    if (e.target.name === "email") {
      userInput[e.target.name] = e.target.value.toLowerCase();
      setUserInput({
        ...userInput,
      });
    } else {
      userInput[e.target.name] = e.target.value;
      setUserInput({
        ...userInput,
      });
    }
  };

  // ********END USER INPUT

  // ********START SUBMISSION

  const objToSend = {
    course_title: courseTitle,
    ...userInput,
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (
      userInput["first_name"] &&
      userInput["last_name"] &&
      emailRegex.test(userInput["email"])
    ) {
      setIsLoading(true);
      axios({
        method: "post",
        url: "https://getform.io/f/ebc9bcaf-b281-48ac-b63e-f381689d3b45",
        data: encode({
          html_template: getHtmlEmailTemplate(courseTitle, userInput),
          ...objToSend,
        }),
      })
        .then(function (response) {
          setIsSuccess(true);
          setUserInput({});
        })
        .catch(function (error) {
          setIsLoading(false);
          alert("Sorry, something went wrong. Please try again later!");
          setUserInput({});
        });
    } else {
      setValidate(true);
    }
  };

  // ********END SUBMISSION
  const closeForm = () => {
    setIsCourseEnquiry(false);
    setIsLoading(false);
    setIsSuccess(false);
    setCourseTitle("");
    setUserInput({});
    setValidate(false);
  };

  // remove invalid style when form is focused
  const handleFocus = () => {
    setValidate(false);
  };

  // handle user input validation
  const handleValidation = (property) => {
    if (validate) {
      if (property === "email") {
        if (emailRegex.test(userInput["email"])) {
          return "";
        } else {
          return "invalid";
        }
      } else {
        if (userInput[property] && userInput[property].length) {
          return "";
        } else {
          return "invalid";
        }
      }
    }
  };

  return (
    <ScrollLocky enabled={isCourseEnquiry}>
      <AnimatePresence>
        {isCourseEnquiry && (
          <Wrapper
            initial="hidden"
            animate={isCourseEnquiry ? "show" : "hidden"}
            variants={container}
            isLoading={isLoading}
            exit={{ opacity: 0 }}
          >
            <div className="container">
              <form onSubmit={handleSubmit} onFocus={handleFocus}>
                <h4 className="title">REGISTER NOW</h4>
                <h5 className="heading">{courseTitle}</h5>

                <motion.div variants={item}>
                  <h6 className="subheading">Your details:</h6>

                  <div className="user-details">
                    <div className="row">
                      <div className="row__col">
                        <input
                          type="text"
                          autoComplete="off"
                          placeholder="First Name*"
                          name="first_name"
                          value={userInput.first_name || ""}
                          onChange={handleUserInput}
                          className={handleValidation("first_name")}
                        />
                      </div>
                      <div className="row__col row__col--right">
                        <input
                          type="text"
                          autoComplete="off"
                          placeholder="Last Name*"
                          name="last_name"
                          value={userInput.last_name || ""}
                          onChange={handleUserInput}
                          className={handleValidation("last_name")}
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="row__col">
                        <input
                          type="text"
                          autoComplete="off"
                          placeholder="Email Address*"
                          name="email"
                          value={userInput.email || ""}
                          onChange={handleUserInput}
                          className={handleValidation("email")}
                        />
                      </div>
                      <div className="row__col row__col--right">
                        <input
                          type="text"
                          autoComplete="off"
                          placeholder="Phone Number"
                          name="phone_number"
                          value={userInput.phone_number || ""}
                          onChange={handleUserInput}
                        />
                      </div>
                    </div>
                  </div>
                </motion.div>

                <motion.div variants={item}>
                  <div className="row row--submit-btn">
                    <p>
                      By clicking submit, you agree to K9 SWiM’s{" "}
                      <a
                        href="/terms-and-conditions/"
                        target="_blank"
                        aria-label="Terms and conditions page"
                      >
                        Terms and Conditions.
                      </a>
                    </p>
                    <ButtonFilled label="REGISTER" />
                  </div>
                </motion.div>
              </form>

              {!isSuccess && (
                <div className="icon-close" role="button" onClick={closeForm}>
                  <motion.span whileTap={{ scale: 0.97 }}>
                    <IconClose />
                  </motion.span>
                </div>
              )}

              {isLoading && (
                <div id="course-enquiry-spinner">
                  {!isSuccess && (
                    <div className="icon">
                      <IconSpinner />
                    </div>
                  )}

                  {isSuccess && (
                    <p className="msg">
                      <span className="icon">
                        <IconCheck />
                      </span>
                      Thank you for your enquiry!
                    </p>
                  )}

                  {isSuccess && (
                    <div
                      className="icon-close"
                      role="button"
                      onClick={closeForm}
                    >
                      <motion.span whileTap={{ scale: 0.97 }}>
                        <IconClose />
                      </motion.span>
                    </div>
                  )}
                </div>
              )}
            </div>
          </Wrapper>
        )}
      </AnimatePresence>
    </ScrollLocky>
  );
};

export default CourseEnquiryForm;
